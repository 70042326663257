import * as filestack from 'filestack-js';
import manifest from '../manifest';
import httpclient from '../store/httpclient';

const {
  clients: {
    filestack: {
      apiKey
    }
  }
} = manifest;

const getClient = () => httpclient.get('/api/filestack/config').then(({ data: clientOptions }) => filestack.init(apiKey, clientOptions));

export default {
  getClient
};
