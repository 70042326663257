import { render, staticRenderFns } from "./dba-list-item.vue?vue&type=template&id=2b41c6b0&scoped=true"
import script from "./dba-list-item.vue?vue&type=script&lang=js"
export * from "./dba-list-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b41c6b0",
  null
  
)

export default component.exports