<template>
  <div>
    <div class="row">
      <div class="col">
        <span class="badge bg-success" v-if="domain.is_external">External</span>
        <h5><b>{{ domain.domain_name }}</b></h5>
      </div>
      <div class="col">
        <button v-if="website !== null" @click="handleLinkDomainClick" class="btn btn-dark btn-sm">Link to ZB Website</button>
      </div>
      <div class="col">
        <button v-if="!domain.is_external" @click="handleSyncDnsClick" class="btn btn-dark btn-sm">Sync DNS Records</button>
      </div>
      <div class="col">
        <button v-if="!domain.is_external" @click="handleSyncNameserversClick" class="btn btn-dark btn-sm">Sync Nameservers</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt>Auto Renew</dt>
      </div>
      <div class="col">
        <dt>Domain Privacy</dt>
      </div>
      <div class="col">
        <dt>Active</dt>
      </div>
      <div class="col">
        <dt>Verified</dt>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dd>Auto Renew is {{ domain.auto_renew ? 'on' : 'off'}}</dd>
        <dd>
          <ZgButton
            @click="toggleAutoRenewModal"
            class="zg-btn zg-btn-default py-1"
          >
            <template v-slot:title>
              Change
            </template>
          </ZgButton>
        </dd>
      </div>
      <div class="col">
          <ToggleButton
              @change="updatePrivacy"
              :active="domainPrivacy"
          />
      </div>
      <div class="col">
        <dd>{{ domain.active }}</dd>
      </div>
      <div class="col">
        <dd>{{ domain.verified }}</dd>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt>Use ZB Nameservers</dt>
      </div>
      <div class="col">
        <dt>Deleted</dt>
      </div>
      <div class="col">
      </div>
      <div class="col">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dd>{{ domain.use_zenbusiness_nameservers }}</dd>
      </div>
      <div class="col">
        <dd>{{ domain.deleted }}</dd>
      </div>
      <div class="col">
      </div>
      <div class="col">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt>Registrant Verification Status</dt>
      </div>
      <div class="col">
        <dt>Verification Email Last Sent</dt>
      </div>
      <div class="col">
        <dt>Registrant Verification Suspend Date</dt>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dd>{{ domain.verified_status }}</dd>
        <dd>
          <ZgButton
            @click="handleClickResendVerificationEmail"
            :isEnable="!isResendVerificationEmailSubimitting"
            :isSubmiting="isResendVerificationEmailSubimitting"
            class="zg-btn zg-btn-default py-1"
          >
            <template v-slot:title>
              Resend verification Email
            </template>
          </ZgButton>
        </dd>
      </div>
      <div class="col">
        <dd>{{ domain.email_last_sent | moment("MMMM DD YYYY") }}</dd>
      </div>
      <div class="col">
        <dd>{{ domain.verification_deadline | moment("MMMM DD YYYY") }}</dd>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt>OpenSRS Created</dt>
      </div>
      <div class="col">
        <dt>OpenSRS Updated</dt>
      </div>
      <div class="col">
        <dt>OpenSRS Expires</dt>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dd>{{ domain.srs_create_datetime| moment("MMMM DD YYYY") }}</dd>
      </div>
      <div class="col">
        <dd>{{ domain.srs_update_datetime| moment("MMMM DD YYYY") }}</dd>
      </div>
      <div class="col">
        <dd>{{ domain.srs_expire_datetime| moment("MMMM DD YYYY") }}</dd>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt>OpenSRS Transfered</dt>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dd>{{ domain.srs_transfer_datetime| moment("MMMM DD YYYY") }}</dd>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt>Transfer Auth Code</dt>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dd>
          <SlotCopy :valueToCopy="authCode">
            {{ authCode }}
          </SlotCopy>
        </dd>
        <dd>
          <ZgButton
            @click="handleGenerateAuthCode"
            :isEnable="!isGenerateAuthCodeLoading"
            :isSubmiting="isGenerateAuthCodeLoading"
            class="zg-btn zg-btn-default py-1 mt-1"
          >
            <template v-slot:title>
              Generate New Code
            </template>
          </ZgButton>
        </dd>
      </div>
    </div>
    <div class="dns">
      <h6><b>Domain Connection</b></h6>
      <div class="row">
        <div class="col">
          <dt>Website Connection status</dt>
        </div>
        <div class="col">
          <dt>Website Connected URL</dt>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dd>{{ domain.website_connection_status || 'Not connected' }}</dd>
        </div>
        <div class="col">
          <dd>{{ domain.website_url || 'Not connected' }}</dd>
        </div>
      </div>
    </div>
    <div class="dns">
      <h6><b>DNS</b></h6>
      <DomainDnsListItem
        v-for="(dnsUuid, dnsIndex) in domain.domain_dns"
        :dnsUuid="dnsUuid"
        :key="dnsUuid"
        :index="dnsIndex"
      >
      </DomainDnsListItem>
    </div>
    <hr v-if="divider"/>
    <AutoRenewModal
      :autoRenew="domain.auto_renew"
      :letExpire="domain.let_expire"
      :isAutoRenewSubmitting="isAutoRenewSubmitting"
      @updateAutoRenew="updateAutoRenew"
      @closeModal="toggleAutoRenewModal"
      v-if="isAutoRenewModalOpen" />
  </div>
</template>

<script>
import ZgButton from "@/components/common/Generics/ZgButton";
import { mapState, mapActions } from 'vuex';
import AutoRenewModal from '@/v2/web-services/components/AutoRenewModal.vue';
import DomainDnsListItem from '@/v2/web-services/components/DomainDnsListItem.vue';
import ToggleButton from '@/v2/web-services/components/ToggleButton.vue';
import SlotCopy from "@/components/v2/common/Generics/SlotCopy.vue";

export default {
  name: 'DomainListItem',

  props: ['domain', 'uuid', 'divider', 'website'],
  components: {
    SlotCopy,
    DomainDnsListItem,
    ToggleButton,
    AutoRenewModal,
    ZgButton
  },

  data() {
    return {
      domainPrivacy: this.domain.domain_privacy,
      isAutoRenewModalOpen: false,
      isAutoRenewSubmitting: false,
      isResendVerificationEmailSubimitting: false,
      authCode: '',
      isGenerateAuthCodeLoading: false
    }
  },

  computed: {
    ...mapState({
      accountUuid: (state) => state.businessEntities.businessEntity.account_uuid
    })
  },

  async created() {
    const { data } = await this.getAuthCode({ domainUuid: this.domain.domain_uuid })
    this.authCode = data;
  },

  methods: {
    ...mapActions({
      updateDomain: 'webServices/updateDomain',
      modifyAutoRenew: 'webServices/modifyAutoRenew',
      resendVerificationEmail: 'webServices/resendVerificationEmail',
      getAuthCode: 'webServices/getAuthCode',
      generateAuthCode: 'webServices/generateAuthCode',
      syncDNS: 'webServices/syncDNS',
      syncNameservers: 'webServices/syncNameservers',
      linkWebsiteToDomain: 'webServices/linkWebsiteToDomain',
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity',
    }),
    async handleLinkDomainClick() {
      await this.linkWebsiteToDomain({ domainUUID: this.domain.domain_uuid, websiteUUID: this.website.website_uuid, accountUuid: this.accountUuid })
      await this.fetchBusinessEntity({ uuid: this.domain.business_entity_uuid })
      this.$router.push({
        name: 'BusinessEntityWebServices',
        params: { uuid: this.domain.business_entity_uuid }
      });
    },
    handleSyncDnsClick() {
      this.syncDNS({ uuid: this.domain.domain_uuid });
    },
    handleSyncNameserversClick() {
      this.syncNameservers({ uuid: this.domain.domain_uuid });
    },
    async updatePrivacy(value) {
      this.domainPrivacy = value;
      const domainUpdateBody = {domain_privacy: this.domainPrivacy}
      await this.updateDomain({domain_uuid: this.domain.domain_uuid, domainUpdateBody });
    },
    toggleAutoRenewModal() {
      this.isAutoRenewModalOpen = !this.isAutoRenewModalOpen;
    },
    async updateAutoRenew(data) {
      if (this.domain.auto_renew !== data.auto_renew || this.domain.let_expire !== data.let_expire) {
        this.isAutoRenewSubmitting = true;
        await this.modifyAutoRenew(
          { domainUuid: this.domain.domain_uuid, autoRenewChanges: data }
        );
        this.isAutoRenewSubmitting = false;
      }
      this.toggleAutoRenewModal();
    },
    async handleClickResendVerificationEmail() {
      this.isResendVerificationEmailSubimitting = true;
      await this.resendVerificationEmail(
        {
          domainUuid: this.domain.domain_uuid,
          domain_name: this.domain.domain_name
        }
      );
      this.isResendVerificationEmailSubimitting = false;
    },
    async handleGenerateAuthCode() {
      const { data } = await this.generateAuthCode( { domainUuid: this.domain.domain_uuid })
      this.authCode = data;
    },
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 5px;
}
.dns {
  padding: em(10, 10);
}
.toggle_container {
    margin: 0px auto;
    background: #efefef;
    width: em(120);
    padding: 10px 0;
    border-radius: 30px;
    transition: all .25s;
}

.toggle_container.active {
    background: #e9ffef;
}
</style>
