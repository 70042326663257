<template>
    <span v-if="dnsRecord">
      <div v-show="!dnsRecord.deleted">
        <label>
          <span class="key">Record Type:</span> {{dnsRecord.type}} 
        </label>
        <span :key="key" v-for="key in Object.keys(dnsRecord.dns_configuration)">
          {{' '}}
        <label :key="key">
          <span class="key">{{key}}</span>: {{dnsRecord.dns_configuration[key]}} 
        </label>
        
        </span>
      </div>
    </span>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'DomainDnsListItem',

  props: ['dnsUuid', 'index'],

  data(){
    return {
      dnsRecord: {
        type: null,
        dns_configuration: {
          
        }
      }
    }
  },

  methods: {
    ...mapActions({
      fetchDomainDns: 'webServices/fetchDomainDns'
    })
  },
  async mounted() {
    this.dnsRecord = (await this.fetchDomainDns({ dnsRecordUuid: this.dnsUuid }));
  }
};
</script>

<style scoped>
  .key {
    font-weight: bold;
  }
</style>
