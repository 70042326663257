 <template>
  <div v-show="!dnsRecord.deleted">
    <label>
      Type
      <select id="account-type" class="form-control" v-model="dnsRecord.type" :disabled="!create">
        <option value="null" :disabled="!create" selected>Please select one</option>
        <option value="a">A</option>
        <option value="cname">CNAME</option>
        <option value="mx">MX</option>
        <option value="txt">TXT</option>
        <option value="srv">SRV</option>
        <option value="forward">FORWARD</option>
      </select>
    </label>
    <span v-if="dnsRecord.type === 'a'">
      <label>
      IP Address *
      <input 
        class="form-control"
        v-model="dnsRecord.dns_configuration.ip_address"
        v-bind:class="{ 'is-invalid': !validateIpAddress(dnsRecord.dns_configuration.ip_address) }"
        required
      />
      <span v-if="!validateIpAddress(dnsRecord.dns_configuration.priority)" class="invalid-feedback"><small>Invalid IP address</small></span>
      </label>
      <label>
        Hostname
        <input 
          class="form-control"
          v-model="dnsRecord.dns_configuration.hostname"/>
      </label>
    </span>
    <span v-if="dnsRecord.type === 'cname'">
      <label>
          Value *
        <input 
          class="form-control"
          v-bind:class="{ 'is-invalid': !validateDomain(dnsRecord.dns_configuration.hostname) }"
          v-model="dnsRecord.dns_configuration.hostname"/>
          <span v-if="!validateDomain(dnsRecord.dns_configuration.hostname)" class="invalid-feedback"><small>Invalid Domain Name</small></span>
      </label>
      <label>
        Hostname
        <input 
          class="form-control" 
          v-model="dnsRecord.dns_configuration.subdomain"
        />
      </label>
    </span>
    <span v-if="dnsRecord.type === 'mx'">
      <label>
          Value *
        <input 
          class="form-control"
          v-bind:class="{ 'is-invalid': !validateDomain(dnsRecord.dns_configuration.hostname) }"
          v-model="dnsRecord.dns_configuration.hostname"/>
          <span v-if="!validateDomain(dnsRecord.dns_configuration.hostname)" class="invalid-feedback"><small>Invalid Domain Name</small></span>
      </label>
      <label>
        Hostname
        <input 
          class="form-control" 
          v-model="dnsRecord.dns_configuration.subdomain"
        />
      </label>
      <label >
        Priority
        <input 
          class="form-control"
          v-model="dnsRecord.dns_configuration.priority"
        />
      </label>
    </span>
    <span v-if="dnsRecord.type === 'txt'">
      <label>
        Text *
        <input 
          class="form-control"
          v-model="dnsRecord.dns_configuration.text"
          v-bind:class="{ 'is-invalid': !validateText(dnsRecord.dns_configuration.text) }"
        />
        <span v-if="!validateDomain(dnsRecord.dns_configuration.hostname)" class="invalid-feedback"><small>Text needs to be longer than 0 characters</small></span>
      </label>
      <label>
        Hostname
        <input 
          class="form-control" 
          v-model="dnsRecord.dns_configuration.subdomain"
        />
      </label>
    </span>
    <span v-if="dnsRecord.type === 'srv'">
      <label>
          Value *
        <input 
          class="form-control"
          v-bind:class="{ 'is-invalid': !validateDomain(dnsRecord.dns_configuration.hostname) }"
          v-model="dnsRecord.dns_configuration.hostname"/>
          <span v-if="!validateDomain(dnsRecord.dns_configuration.hostname)" class="invalid-feedback"><small>Invalid Domain Name</small></span>
      </label>
      <label>
        Hostname
        <input 
          class="form-control" 
          v-model="dnsRecord.dns_configuration.subdomain"
        />
      </label>
      <label>
        Priority
        <input 
          class="form-control"
          v-model="dnsRecord.dns_configuration.priority"
        />
      </label>
      <label >
        Port *
        <input 
          class="form-control"
          v-model="dnsRecord.dns_configuration.port"
          v-bind:class="{ 'is-invalid': !validatePort(parseInt(dnsRecord.dns_configuration.port)) }"
        />
        <span v-if="!validateDomain(dnsRecord.dns_configuration.hostname)" class="invalid-feedback"><small>Invalid Port Number. Must be between 0 and  65535.</small></span>
      </label>
      <label>
        Weight
        <input class="form-control" v-model="dnsRecord.dns_configuration.weight"/>
      </label>
    </span>
    <span v-if="dnsRecord.type === 'forward'">
      <label >
        Destination URL
        <input 
          class="form-control"
          v-model="dnsRecord.dns_configuration.destination_url"
          v-bind:class="{ 'is-invalid': !validateDomain(dnsRecord.dns_configuration.destination_url) }"
        />
        <span v-if="!validateDomain(dnsRecord.dns_configuration.destination_url)" class="invalid-feedback"><small>Invalid Domain Name</small></span>
     </label>
      <label>
        Hostname
        <input 
          class="form-control" 
          v-model="dnsRecord.dns_configuration.subdomain"
        />
      </label>
    </span>
    
    <div>
      <ZgButton type="button" class="btn btn-primary btn-sm ms-auto" @click="handleSaveDnsClick">
      <template v-slot:title>
            Save
          </template>  
      </ZgButton>
      <ZgButton type="button" class="btn btn-danger btn-sm ms-auto" @click="handleDeleteDnsRecord">
        <template v-slot:title>
        Delete
        </template>
      </ZgButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { domainNameMixin } from '@/components/mixins';
import ZgButton from '@/components/common/Generics/ZgButton.vue';

export default {
  name: 'DomainDnsEditListItem',

  props: ['domainUuid', 'dnsRecordUuid', 'index', 'create'],
  mixins: [domainNameMixin],
  components: {
    ZgButton
  },

  data(){
    return {
      dnsRecord: {
        type: null,
        dns_configuration: {
          subdomain: '',
          ip_address: ''
        },
        deleted: false
      },
    }
  },

  methods: {
    ...mapActions({
      fetchDomainDns: 'webServices/fetchDomainDns',
      updateDomainDns: 'webServices/updateDomainDns',
      deleteDomainDns: 'webServices/deleteDomainDns',
      createDomanDns: 'webServices/createDomainDns'
    }),
    
    async handleSaveDnsClick() {
      const numericFields = ['priority', 'port', 'weight'];
      const keys = Object.keys(this.dnsRecord.dns_configuration);
      keys.map(key => {
        if(numericFields.includes(key)) {
          this.dnsRecord.dns_configuration[key] = parseInt(this.dnsRecord.dns_configuration[key]);
        }
      });

      if(this.create) {
        this.domainDns = await this.createDomanDns({domainUuid: this.domainUuid, body: this.dnsRecord});
      } else {
        this.domainDns = await this.updateDomainDns({dnsRecordUuid: this.dnsRecordUuid, body: this.dnsRecord });
      }
    },
    async handleDeleteDnsRecord() {
      await this.deleteDomainDns({dnsRecordUuid: this.dnsRecordUuid});
      this.dnsRecord.deleted = true;
    },
    validateIpAddress(ip) {
      const regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i;
      return regex.exec(ip);
    },
    validateText: (text) => text.length > 0,
    validatePort: (port) => port >= 0 && port <= 65535
  },
  async mounted() {
    if (!this.create) {
      this.dnsRecord = (await this.fetchDomainDns({ dnsRecordUuid: this.dnsRecordUuid }));
    }
  }
};
</script>

<style scoped>
label {
  display: block;
}
</style>
