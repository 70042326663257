<template>
  <div class="box">
    <div class="row">
      <div class="col-md-8">
        <dt>Company Name (Only if Contact is a company)</dt>
        <dd><input class="form-control" :value="address.company_name" @input="update('company_name', $event.target.value)"/></dd>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <dt>First Name</dt>
        <div><label v-if="validationAddress && !firstNameIsRequired" class="invalid-input-message">First Name is required.</label></div>
        <dd><input class="form-control" :value="address.first_name" @input="update('first_name', $event.target.value)"/></dd>
      </div>
      <div class="col-md-4">
        <dt>Middle Name</dt>
        <dd><input class="form-control" :value="address.middle_name" @input="update('middle_name', $event.target.value)"/></dd>
      </div>
      <div class="col-md-4">
        <dt>Last Name</dt>
        <div><label v-if="validationAddress && !lastNameIsRequired" class="invalid-input-message">Last Name is required.</label></div>
        <dd><input class="form-control" :value="address.last_name" @input="update('last_name', $event.target.value)"/></dd>
      </div>
      <div class="col-md-4">
        <dt>Suffix</dt>
        <select class="form-control" :value="address.suffix" @input="update('suffix', $event.target.value)">
          <option disabled>Select a suffix</option>
          <option value="">None</option>
          <option>DDS</option>
          <option>MD</option>
          <option>PhD</option>
          <option>Jr.</option>
          <option>Sr.</option>
          <option>I</option>
          <option>II</option>
          <option>III</option>
          <option>IV</option>
          <option>V</option>
          <option>VI</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <dt>Phone</dt>
        <div><label v-if="validationAddress && !phoneIsValid" class="invalid-input-message">Phone is required or invalid.</label></div>
        <dd><input class="form-control" :value="address.phone" @input="update('phone', $event.target.value)"/></dd>
      </div>
      <div class="col-md-4">
        <dt v-bind:class="{'text-danger': !validateEmail(address.email)}">Email</dt>
        <div><label v-if="validationAddress && !emailIsValid" class="invalid-input-message">Email is required or invalid.</label></div>
        <dd><input class="form-control" :value="address.email" @input="update('email', $event.target.value)"/></dd>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <dt>Address 1</dt>
        <div><label v-if="validationAddress && !address1IsRequired" class="invalid-input-message">Address_1 is required.</label></div>
        <dd><input class="form-control" :value="address.address_1" @input="update('address_1', $event.target.value)"/></dd>
      </div>
      <div class="col-md-8">
        <dt>Address 2</dt>
        <dd><input class="form-control" :value="address.address_2" @input="update('address_2', $event.target.value)"/></dd>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dt>City</dt>
        <div><label v-if="validationAddress && !cityRequired" class="invalid-input-message">City is required.</label></div>
        <dd><input class="form-control" :value="address.city" @input="update('city', $event.target.value)"/></dd>
      </div>
      <div class="col">
        <dt>State</dt>
        <div><label v-if="validationAddress && !stateIsValid" class="invalid-input-message">Please use the two-letter uppercase state code.</label></div>
        <dd><input class="form-control" :value="address.state" @input="update('state', $event.target.value)"/></dd>
      </div>
      <div class="col">
        <dt>Zip</dt>
        <div><label v-if="validationAddress && !zipIsValid" class="invalid-input-message">Zip Code is required or invalid.</label></div>
        <dd><input class="form-control" :value="address.zip" @input="update('zip', $event.target.value)"/></dd>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <dt>County</dt>
        <dd><input class="form-control" :value="address.county" @input="update('county', $event.target.value)"/></dd>
      </div>
      <div class="col-md-4">
        <dt>Country</dt>
        <div><label v-if="validationAddress && !countryIsRequired" class="invalid-input-message">Country is required.</label></div>
        <dd><input class="form-control" :value="address.country" @input="update('country', $event.target.value)"/></dd>
      </div>
    </div>
  </div>
</template>
<script>

import { inputValidationsMixin } from '../../mixins';

export default {
  name: 'AddressWithNameEdit',

  mixins: [inputValidationsMixin],

  props: ['value', 'isValidationAddress'],
  
  computed: {
    address() {
      return this.value ? this.value : {}
    },
    validationAddress() {
      if (this.isValidationAddress) {
        return true;
      } else if (this.firstNameIsRequired || this.lastNameIsRequired || this.phoneIsValid 
          || this.emailIsValid || this.address1IsRequired || this.cityRequired || this.stateIsValid || this.zipIsValid
          ||  (Boolean(this.address.state))
          || (Boolean(this.address.zip))
          || (Boolean(this.address.country))
      
        )

      { 
        return true;
      }
      else {
        return false;
      }
    },
  
    firstNameIsRequired() {
      return (Boolean(this.address.first_name)); 
    },    
    lastNameIsRequired() {
      return (Boolean(this.address.last_name));
    },
    phoneIsValid() {
      return (Boolean(this.address.phone));
    },
    emailIsValid() {
      return (Boolean(this.address.email)) && this.validateEmail(this.address.email)
    },  
    address1IsRequired() {
            return (Boolean(this.address.address_1))
    },
    cityRequired() {
      return  (Boolean(this.address.city));
    }, 
    stateIsValid() {
      return (Boolean(this.address.state)) && (this.address.state === this.address.state.toUpperCase()) && (this.address.state.length === 2);
    },
    zipIsValid() {
      return (Boolean(this.address.zip)) && (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.address.zip));
    },
    countryIsRequired() {
      return (Boolean(this.address.country));
    }
  },

  
  methods: {
    emitAddress() {
      this.$emit('input', this.address);
    },
    update(key, value) {
      this.$emit('input', { ...this.address, [key]: value })
    },
    validateChildInputs(){
      return this.firstNameIsRequired && this.lastNameIsRequired && 
        this.phoneIsValid && this.emailIsValid && this.address1IsRequired 
        && this.cityRequired && this.zipIsValid && this.stateIsValid && this.validationAddress
        && this.countryIsRequired;
    },
    validateChildInputsOptional(){
      if (this.validationAddress)
    {
        return this.firstNameIsRequired && this.lastNameIsRequired && 
          this.phoneIsValid && this.emailIsValid && this.address1IsRequired 
          && this.cityRequired && this.zipIsValid && this.stateIsValid && this.validationAddress 
          && this.countryIsRequired;
     
    }
          else
        return true;
    }

  }
};
</script>

<style lang="scss" scoped>
  .invalid-input-message {
    color: red;
  }
</style>
