import { render, staticRenderFns } from "./business-entity.vue?vue&type=template&id=40be3743&scoped=true"
import script from "./business-entity.vue?vue&type=script&lang=js"
export * from "./business-entity.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40be3743",
  null
  
)

export default component.exports