<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Users who viewed this document</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
            <h6 v-if="owner_read" class="text-success text-center">Owner viewed this document</h6>
            <h6 v-else class="text-danger text-center">Owner did not see this document</h6>
            <table v-if="read_document.length > 0" class="table table-borderless">
                <thead>
                  <tr>
                    <th>User email</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="r_document in read_document" v-bind:key="r_document.document_read_uuid">
                        <td>{{ r_document.user_email }}</td>
                        <td>{{ r_document.updated_datetime | moment("MMMM DD YYYY, h:mm:ss a") }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReadDocumentModal',

  props: ['read_document', 'owner_read']

};
</script>

<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
  }

  .modal-content {
    max-height: 80vh;
    overflow-y: scroll;
  }
</style>
