<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure you would like to delete the following Business Entity? This will remove
            the business entity information and orders related to the entity from the customer's dashboard and this page
            in zengarden.</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <strong>Name: </strong>{{ businessEntity.business_entity_name }}
        </div>
        <div class="modal-footer">
          <div class="btn btn-danger action" @click="handleBusinessEntityDelete">Yes, I want to delete</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeleteBusinessEntityModal',

  props: ['businessEntity'],

  methods: {
    handleBusinessEntityDelete() {
      this.$store.dispatch('businessEntities/deleteBusinessEntity', { businessEntityUuid: this.businessEntity.business_entity_uuid })
        .then(() => {
          this.$emit('deleted');
        });
    }
  }

};
</script>

<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
  }
</style>
