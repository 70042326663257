<template>
  <div>
    <div class="row heading">
      <div class="col">
        <h2 class="header">Document View / Download</h2>
        <div class="box">
          <p>Please wait while we load/download your document.</p>
          <p>Close this window when complete.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'BusinessEntityDocumentView',

  props: ['documentUuid'],

  computed: mapState({
    documentViewUrl: (state) => (state.businessEntities.documentViewUrl),
    ...mapGetters({
      getBusinessEntityUuid: 'businessEntities/getBusinessEntityUuid'
    }),
  }),

  created() {
    this.$store.dispatch('businessEntities/getAndOpenSecuredDocumentUrl', { uuid: this.getBusinessEntityUuid, documentUuid: this.documentUuid })
      .then(() => {
        window.location.href = this.documentViewUrl;
      });
  }
};
</script>

<style lang="scss" scoped>

</style>
