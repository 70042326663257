<template>
  <div class="row heading">
    <div class="col">
      <h2 class="header">DBAs</h2>
      <div class="box position-relative">
        <LoadingWrapper
          size="sm"
          :isLoading="isLoading" /> 
        <template v-if="!isLoading">
          <table class="table table-sm table-borderless" v-if="dbas && dbas.length > 0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Jurisdiction</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <DbaListItem v-for="dba in dbas" v-bind:key="dba.dba_entity_uuid" :dba="dba"></DbaListItem>
            </tbody>
          </table>
          <p v-else v-html="message"></p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DbaListItem from './dba-list-item';
import LoadingWrapper from '@/components/common/LoadingWrapper';

export default {
  name: 'BusinessEntityDbas',

  components: {
    DbaListItem,
    LoadingWrapper
  },

  data () {
    return {
      isLoading: true,
      message: 'There are no DBAs associated with this business entity.'
    }
  },

  computed: {
    ...mapState('dba', ['dbas']),
  },

  methods: {
    ...mapActions({
      fetchDbas: 'dba/fetchDbas',
      resetDbaState: 'dba/resetDbaState',
    })
  },

  async created() {
    const fetchDbas = await this.fetchDbas({ uuid: this.$route.params.businessEntityUuid });
    if (fetchDbas?.status !== 200) {
      this.message = `There was an issue fetching the DBAs. Please try again or contact support.
        <br><strong>Status:</strong> ${fetchDbas.status} ${fetchDbas.statusText}`;
    }
    this.isLoading = false;
  },
  
  beforeDestroy() {
    this.resetDbaState();
  },
};
</script>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
  }
  .top25{
    margin-top: 25px;
  }
  .account-list-header-center {
    text-align: center;
  }
</style>
