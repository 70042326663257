<template>
  <tr>
    <td>
      <span v-if="!editing">{{ dbaParam.name }}</span>
      <input v-else class="form-control" v-model="dbaParam.name"/>
    </td>
    <td>{{ dbaParam.jurisdiction_code }}</td>
    <td>{{ dbaParam.active_flag }}</td>
    <td>
      <button class="btn btn-info btn-sm" v-if="!editing" @click="handleEditClick">Edit</button>
      <button class="btn btn-success btn-sm" v-if="editing" @click="handleSaveClick">Save</button>
      <button class="btn btn-danger btn-sm" v-if="editing" @click="handleCancelEditClick">Cancel</button>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DbaListItem',

  props: ['dba'],

  data() {
    return {
      editing: false,
      dbaParam: this.dba
    };
  },

  methods: {
    ...mapActions({
      fetchDbas: 'dba/fetchDbas',
      updateDbas: 'dba/updateDbas'
    }),
    handleSaveClick() {
      this.updateDbas({ uuid: this.dbaParam.dba_entity_uuid, name: this.dbaParam.name })
        .then(() => {
          this.fetchDbas({ uuid: this.$route.params.businessEntityUuid });
        });
      this.editing = false;
    },
    handleCancelEditClick() {
      this.editing = false;
    },
    handleEditClick() {
      this.editing = true;
    }
  }
};
</script>

<style scoped>

</style>
