<template>
  <div v-if="showDNS">
    <dt>DNS Records</dt>
    <DomainDnsEditListItem
      v-show="showNew"
      :create="true"
      :domainUuid="domain.domain_uuid"
    />
    <ZgButton 
      @click="handleCreateDnsButton" 
      class="btn btn-primary btn-sm ms-auto"
    >
    <template v-slot:title>
      {{ showNew ? 'Cancel' : 'Add new record'}}
        </template>
    </ZgButton>
    
    <DomainDnsEditListItem
    v-for="(dnsUuid, dnsIndex) in domain.domain_dns"
    :dnsRecordUuid="dnsUuid"
    :key="dnsUuid"
    :index="dnsIndex"
    />
    
  </div>
</template>

<script>
import manifest from '@/manifest';
import DomainDnsEditListItem from '@/v2/web-services/components/DomainDnsEditListItem.vue';
import { domainNameMixin } from '@/components/mixins';
import ZgButton from '@/components/common/Generics/ZgButton.vue';

export default {
  name: 'DomainDnsEdit',

  props: ['domain'],
  mixins: [domainNameMixin],
  data() {
    return {
      showNew: false,
      newRecord: null,
      showDNS: manifest.environment === 'local' || manifest.features.opensrsMigration.enabled
    }
  },
  components:  {
    DomainDnsEditListItem,
    ZgButton
  },
  methods:{
    handleCreateDnsButton() {
      this.showNew = !this.showNew;
    }
  }
};
</script>

<style scoped>

</style>
