<template>
  <tr class="vtop changelog-item" :id="timestamp| moment('x')">
    <td><span class="sticky">{{ timestamp| moment("MMMM DD YYYY") }}</span></td>
    <td><span class="sticky">{{ change.updated_by }}</span></td>
    <td>

      <table class="table table-sm table-borderless" v-if="change.values_changed">
        <thead >
        <tr><td><dt class="dt">Changed</dt></td></tr>
          <tr>
            <td><dt>Key</dt></td>
            <td><dt>Old Value</dt></td>
            <td><dt>New Value</dt></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in change.values_changed" v-bind:key="key" class="table-active">
            <td>{{ key|lastval }}</td>
            <td>{{ item.old_value }}</td>
            <td>{{ item.new_value }}</td>
          </tr>
        </tbody>
      </table>

      <table v-if="change.dictionary_item_removed" class="table table-sm table-borderless">
        <thead>
        <tr><td><dt class="dt">Removed</dt></td></tr>
          <tr>
            <td><dt>Key</dt></td>
            <td><dt>Value</dt></td>
          </tr>
        </thead>
        <tr v-for="(item, key) in change.dictionary_item_removed" v-bind:key="key" class="table-active">
          <td>{{ key|lastval }}</td>
          <td>{{ item }}</td>
        </tr>
      </table>

      <table v-if="change.dictionary_item_added" class="table table-sm table-borderless">
        <thead>
        <tr><td><dt class="dt">Added</dt></td></tr>
          <tr>
            <td><dt>Key</dt></td>
            <td><dt>Value</dt></td>
          </tr>
        </thead>
        <tr v-for="(item, key) in change.dictionary_item_added" v-bind:key="key" class="table-active">
          <td>{{ key|lastval }}</td>
          <td>{{ item }}</td>
        </tr>
      </table>

      <table v-if="change.iterable_item_removed" class="table table-sm table-borderless">
        <thead>
        <tr><td><dt class="dt">Added</dt></td></tr>
        <tr>
          <td><dt>Key</dt></td>
          <td><dt>Value</dt></td>
        </tr>
        </thead>
        <tr v-for="(item, key) in change.iterable_item_removed" v-bind:key="key" class="table-active">
          <td>{{ key|lastval }}</td>
          <td>{{ item }}</td>
        </tr>
      </table>

      <table v-if="change.iterable_item_added" class="table table-sm table-borderless">
        <thead>
        <tr><td><dt class="dt">Added</dt></td></tr>
        <tr>
          <td><dt>Key</dt></td>
          <td><dt>Value</dt></td>
        </tr>
        </thead>
        <tr v-for="(item, key) in change.iterable_item_added" v-bind:key="key" class="table-active">
          <td>{{ key|lastval }}</td>
          <td>{{ item }}</td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
import join from 'lodash/join';
import slice from 'lodash/slice';
import replace from 'lodash/replace';

export default {
  name: 'ChangelogListItem',

  props: ['change', 'timestamp'],

  filters: {
    lastval(value) {
      return join(slice(replace(replace(value, '\'][\'', '.'), '\'][\'', '.'), 6, -2), '');
    }
  }
};
</script>
<style scoped>
  .changelog-item {
    border-top: 2px solid #000000;
  }
  .changelog-item:nth-child(even) {
    background-color: rgba(0, 0, 0, .015);
  }
  .vtop {
    vertical-align: top;
  }
  .sticky {
    position: sticky;
    display: block;
  }
  .dt {
    font-size: 10pt;
    color: #a9a9a9;
  }

  dt {
    color: #000000;
    font-size: 10pt;
  }

</style>
