<template>
  <div class="modal">
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="modal-title mb-1">Auto-renew Settings</h5>
          <div class="mb-1">
            <input type="radio" id="auto_renew" value="auto_renew" v-model="picked">
            <label for="auto_renew" class="ms-1">Auto-renew</label>
          </div>
          <div class="mb-1">
            <input type="radio" id="let_expire" value="let_expire" v-model="picked">
            <label for="let_expire" class="ms-1">Expire with notifications</label>
          </div>
          <div class="mb-1">
            <input type="radio" id="silent_expire" value="silent_expire" v-model="picked">
            <label for="silent_expire" class="ms-1">Expire without notifications</label>
          </div>
          <div class='mt-3'>
            <ZgButton
              @click="$emit('closeModal')"
              :isEnable="!isAutoRenewSubmitting"
              class="zg-btn zg-btn-light action me-2"
            >
              <template v-slot:title>
                Cancel
              </template>
            </ZgButton>
            <ZgButton
              @click="handleChangeAutoRenew"
              :isEnable="!isAutoRenewSubmitting"
              :isSubmiting="isAutoRenewSubmitting"
              class="zg-btn zg-btn-default"
            >
              <template v-slot:title>
                Save
              </template>
            </ZgButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZgButton from "@/components/common/Generics/ZgButton";

export default {
  name: 'AutoRenewModal',

  props: ['autoRenew', 'letExpire', 'isAutoRenewSubmitting'],

  components:{
    ZgButton,
  },

  data() {
    return {
      picked: null
    }
  },

  mounted() {
    this.picked = (this.autoRenew && 'auto_renew') || (this.letExpire && 'let_expire') || 'silent_expire';
  },

  methods: {
    handleChangeAutoRenew () {
      const data = {
        auto_renew: this.picked === 'auto_renew',
        let_expire: this.picked === 'let_expire'
      }
      this.$emit('updateAutoRenew', data);
    }
  }
};
</script>

<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
  }

  .modal-content {
    max-height: 80vh;
    overflow-y: scroll;
  }
</style>
