<template>
  <div>
    <div class="menu">
      <dt>Business Entity Name</dt>
      <dd class="wrap">
        <SlotCopy :valueToCopy="businessEntity.business_entity_name">
          {{ businessEntity.business_entity_name }}
        </SlotCopy>
      </dd>
      <dt>Business Entity Type</dt>
      <dd id="entity-type">
        <SlotCopy :valueToCopy="businessEntity.business_entity_type">
          {{ businessEntity.business_entity_type | businessEntityType }}
        </SlotCopy>
      </dd>
      <dt>Domestic Jurisdiction</dt>
      <dd>
        <SlotCopy :valueToCopy="domesticJurisdiction">
          {{ domesticJurisdiction || 'unknown' }}
        </SlotCopy>
      </dd>
      <div v-if="foreignJurisdictionListString">
        <dt>Foreign Jurisdictions</dt>
        <dd>{{ foreignJurisdictionListString }}</dd>
      </div>
      <dt>Formation Status</dt>
      <dd id="entity-formation-status">
        <SlotCopy :valueToCopy="businessEntity.formation_status">
          {{ businessEntity.formation_status || 'unknown' }}
        </SlotCopy>
      </dd>
      <dt>Formed By</dt>
      <dd id="entity-formed-by">
        <SlotCopy :valueToCopy="businessEntity.formed_by">
          {{ businessEntity.formed_by || 'unknown' }}
        </SlotCopy>
      </dd>
      <dt>GCP Logs</dt>
      <dd>
        <a :href="logsBusinessEntityUuidUrl" target="_blank">Business</a>
      </dd>
      <dt>Salesforce</dt>
      <dd>
        <a :href="businessEntitySalesforceUrl" target="_blank">Business Entity</a>
      </dd>

    </div>
    <div class="menu" v-if="!isUnisearch">
      <h2>Active Recurring Services</h2>
      <dt v-if="!businessEntity.has_ra && !businessEntity.has_wfg">None</dt>
      <dt v-if="businessEntity.has_ra">Registered Agent
      </dt>
      <button v-if="displayCoupon" type="button" class="btn btn-warning top25"
              :class="{ disabled: isRecurlyAccount() || !couponStatus.buttonStatus }"
        @click="openJourneyModal()">
        Issue Coupon
      </button>
      <h2 style="margin-top: 10px;" v-if="enableCouponStatusFeatureFlag && displayCoupon && couponStatus.statusMessage">Coupon Status</h2>
      <dt v-if="enableCouponStatusFeatureFlag && displayCoupon && couponStatus.statusMessage">{{ couponStatus.statusMessage }}
      </dt>
      <dt v-if="businessEntity.has_wfg">Worry Free Guarantee</dt>
      <dt v-if="hasOngoingBOF">Ongoing BOF</dt>
    </div>
    <div class="menu">
      <h2>Document Upload</h2>
      <h3><a href="" @click.prevent="handleDocumentDescriptionsClick">Document Type Descriptions</a></h3>
      <dt>Type</dt>
      <select v-model="subselected" id="doc-type-select">
        <option/>
        <option value="formation">Formation</option>
        <option value="ein">EIN</option>
        <option value="soo">SOO</option>
        <option value="form_2553">Form 2553 (S-Corp)</option>
        <option disabled>-----</option>
        <option value="amendment">Amendment</option>
        <option value="annual_report">Annual Report</option>
        <option value="appoint_of_officer_and_directors">Appoint of Officer and Directors</option>
        <option value="bof_transcript">Beneficial Ownership Filing Transcript</option>
        <option value="business_license_report">Business License Report</option>
        <option value="cancel_dissolution_termination">Cancel/Dissolution/Termination</option>
        <option value="certified">Certified</option>
        <option value="compliance_notification">Compliance Notification</option>
        <option value="dba">DBA</option>
        <option value="foreign_qualification">Foreign Qualification</option>
        <option value="good_standing">Good Standing</option>
        <option value="insurance_bop">Insurance (BoP)</option>
        <option value="insurance_health">Insurance (Health)</option>
        <option value="mail">Mail</option>
        <option value="operating_agreement">Operating Agreement</option>
        <option value="operating_agreement_corporate_bylaws">Operating Agreement/Corporate Bylaws</option>
        <option value="partner_unspecified">Partner - Unspecified</option>
        <option value="other">Other</option>
        <option value="publication">Publication</option>
        <option value="reinstatement">Reinstatement</option>
        <option value="registered_agent_filing">Registered Agent Filing</option>
        <option value="sop">SoP</option>
        <option value="state_resource">State Resource</option>
        <option value="taxes">Taxes</option>
      </select>
      <div class="mt-2" v-if="isRegisteredAgentOrDev && showCreateSalesforceCase">
        <input type="checkbox" name="compliance_notification_case" id="compliance_notification_case" v-model="createComplianceNotificationCase">
        <label for="compliance_notification_case">Create SF Case</label>
      </div>
      <div class="btn btn-info" @click="openFilePicker">Add Files</div>
    </div>
    <div class="menu">
      <h2>Internal Links</h2>
      <h3><a href="" @click.prevent="handleAccountClick">Account</a></h3>
    </div>
    <div class="menu" v-if="!isUnisearch">
      <h2>Actions</h2>
      <div class="btn btn-info" @click="handleEditClick">Edit Business Entity</div>
      <div class="btn btn-info" v-if="hasWebServices" @click="handleEditWebServicesClick">Edit Web Services</div>
      <div class="btn btn-info" v-if="hasWebServices" @click="handleAddExternalDomainClick">Add External Domain</div>
      <div class="btn btn-danger" @click="handleDeleteClick" data-toggle="tooltip" data-placement="top"
           title="Hides a business entity from the customer's dashboard and ZenGarden. Often used to hide a duplicate.">Hide Business Entity</div>
    </div>
    <DeleteBusinessEntityModal v-if="deleteClicked" @close="deleteClicked = false" :business-entity="businessEntity"
                               @deleted="handleAccountClick"></DeleteBusinessEntityModal>

    <CouponJourneyModal
      :isJourneyUpdateSuccess="isJourneyUpdateSuccess"
      :isJourneyUpdateSubmitting="isJourneyUpdateSubmitting"
      v-if="isJourneyModalOpen"
      @closeJourneyModal="closeJourneyModal"
      @handleCreateCouponJourney="handleCreateCouponJourney()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState} from 'vuex';
import toLower from 'lodash/toLower';
import filestackClient from '../../client/filestack';
import DeleteBusinessEntityModal from './delete-entity-modal';
import { hasPermissionMixin } from '@/components/mixins';
import manifest from '../../manifest';
import SlotCopy from "@/components/v2/common/Generics/SlotCopy.vue";
import { logsBusinessEntityUuidUrl } from '@/components/utils/dynamicUrls.js';
import CouponJourneyModal from "@/components/business-entities/CouponJourneyModal";
import { getClient } from "@/client/ldClient";
import { couponJourneyEnabled, couponJourneyStatusEnabled } from "@/assets/utils/feature-utils";
import { getCouponStatus } from "@/assets/utils/coupons";

export default {
  name: 'BusinessEntitySideBar',

  mixins: [
    hasPermissionMixin
  ],

  filters: {
    businessEntityType(entityType) {
      if (!entityType) return '';
      return toLower(entityType) === 'corp' ? 'CORPORATION' : entityType;
    }
  },

  components: {
    SlotCopy,
    CouponJourneyModal,
    DeleteBusinessEntityModal

  },

  data() {
    return {
      selected: 'Document',
      subselected: null,
      deleteClicked: false,
      createComplianceNotificationCase: false,
      isJourneyModalOpen: false,
      isJourneyUpdateSuccess: false,
      isJourneyUpdateSubmitting: false,
      modalData: {},
      featureFlagClient: null,
      isFeatureEnabled: false,
      enableCouponJourneyFeatureFlag: false,
      promotionData: null,
      couponJourney: null,
      enableCouponStatusFeatureFlag: false
    };
  },

  async created() {
    this.featureFlagClients = await getClient();
    this.enableCouponJourneyFeatureFlag = couponJourneyEnabled( this.featureFlagClients);
    this.enableCouponStatusFeatureFlag = couponJourneyStatusEnabled( this.featureFlagClients);
  },
  computed: {
    ...mapState ({
      businessEntities: (state) => state.businessEntities.businessEntities,
      businessEntity: (state) => state.businessEntities.businessEntity,
      jurisdictions: (state) => state.businessEntities.jurisdictions,
      businessEntityTasks: (state) => state.businessEntities.businessEntityTasks,
      subscriptionBillingItems: (state) => state.orders.subscriptionBillingItems,
      account: (state) => state.accounts.account,
    }),
   ...mapGetters({
      getBusinessEntityUuid: 'businessEntities/getBusinessEntityUuid',
      domains: 'webServices/getDomains',
      websites: 'webServices/getWebsites'
    }),
    couponStatus() {
      const raOrder = this.raOrder;
      const couponStatus = getCouponStatus(this.couponJourney, this.promotionData, raOrder?.term_end_date);
      return couponStatus;
    },
    displayCoupon() {
      return this.businessEntity.has_ra
        && this.businessEntity.formation_status ==='formed'
        && this.enableCouponJourneyFeatureFlag
    },
    isProduction() {
      return manifest.environment === 'production';
    },
    hasOngoingBOF() {
      return this.businessEntityTasks.find((ot) =>  {
        return ot.order_task_type === 'ongoing_bof_compliance' && ot.order_task_status === 'active';
      });
    },
    raOrder() {
      return this.businessEntityTasks.find((ot) => ot.order_task_type === 'registered_agent_service' && ot.order_task_status === 'active');
    },
    hasWebServices() {
      const hasAtLeastOneActiveWebsite = this.websites.find((website) => website.active);
      return !!(
        this.businessEntityTasks.find((ot) => ot.order_task_type === 'static_website')
        || this.domains.length > 0 || hasAtLeastOneActiveWebsite
      );
    },
    domesticJurisdiction() {
      const domesticJurisdiction = this.jurisdictions.find((j) => j.domestic_flag);
      return domesticJurisdiction ? domesticJurisdiction.jurisdiction_code : '';
    },
    foreignJurisdictionListString() {
      return this.jurisdictions
        .filter((j) => !j.domestic_flag)
        .map((j) => j.jurisdiction_code)
        .join(', ');
    },
    logsBusinessEntityUuidUrl() {
      return logsBusinessEntityUuidUrl(this.isProduction, this.getBusinessEntityUuid);
    },
    businessEntitySalesforceUrl() {
      return `${manifest.clients.salesforce.host}lightning/r/${this.businessEntity.business_entity_sf_id}/view`;
    },
    isUnisearch() {
      return this.hasPermission('partner:unisearch');
    },
    isRegisteredAgentOrDev() {
      return this.hasPermission('access:zb-registered-agent') || this.hasPermission('access:developers');
    },
    showCreateSalesforceCase() {
      return this.subselected === 'compliance_notification';
    },
    domesticJurisdictionCode() {
      const domesticJurisdiction = this.jurisdictions.find((jur) => jur.domestic_flag);
      return domesticJurisdiction ? domesticJurisdiction.jurisdiction_code : '';
    }
  },

  methods: {
    ...mapActions({
      fetchSubscriptionBillingItems: 'orders/fetchSubscriptionBillingItems',
      fetchOrderBillingItemPromotions: 'orders/fetchOrderBillingItemPromotions',
      fetchJourneyForAccount: 'businessEntities/fetchJourneyForAccount',
      createCouponJourney: 'businessEntities/createCouponJourney',
    }),
    isRecurlyAccount() {
      return this.account.recurly_account
    },
    handleAccountClick() {
      this.$router.push({ name: 'Account', params: { accountUuid: this.businessEntity.account_uuid } });
    },
    handleDocumentDescriptionsClick() {
      this.$router.push({ name: 'DocumentDescriptions' });
    },
    handleEditClick() {
      this.$router.push({ name: 'BusinessEntityEdit', params: { businessEntityUuid: this.getBusinessEntityUuid } });
    },
    handleEditWebServicesClick () {
      this.$router.push({ name: 'BusinessEntityWebServicesEdit',
        params: { businessEntityUuid: this.getBusinessEntityUuid }})
    },
    handleAddExternalDomainClick() {
      this.$router.push({
        name: 'BusinessEntityExternalDomain',
        params: { businessEntityUuid: this.getBusinessEntityUuid }
      });
    },
    handleDeleteClick() {
      this.deleteClicked = true;
    },
    openFilePicker() {
      const options = {
        maxFiles: 1,
        uploadInBackground: false,
        displayMode: 'overlay',
        onUploadDone: (res) => this.updateFile(res)
      };
      filestackClient.getClient().then((client) => {
        const picker = client.picker(options);
        picker.open();
      })
      .catch(
        // eslint-disable-next-line no-empty
      );
    },
    updateFile(response) {
      const category = this;
      this.$store.dispatch('businessEntities/addDocument', {
        accountUuid: this.businessEntity.account_uuid,
        businessEntityUuid: this.getBusinessEntityUuid,
        fileName: response.filesUploaded[0].filename,
        fileLink: response.filesUploaded[0].url,
        fileUuid: response.filesUploaded[0].handle,
        fileCategory: category.selected,
        fileSubcategory: category.subselected,
        createComplianceNotificationCase: this.createComplianceNotificationCase,
        jurisdictionCode: this.domesticJurisdictionCode
      })
        .then(() => {
          this.$store.dispatch('businessEntities/fetchDocuments', { uuid: this.getBusinessEntityUuid });
        });
    },

    openJourneyModal() {
      this.isJourneyUpdateSuccess = true;
      this.isJourneyModalOpen = true;
    },
    closeJourneyModal() {
      this.isJourneyUpdateSubmitting = false
      this.isJourneyUpdateSuccess = true
      this.isJourneyModalOpen = false
    },

    async handleCreateCouponJourney() {
      this.isJourneyUpdateSubmitting = true;
      const raOrder = this.raOrder;
      await this.fetchSubscriptionBillingItems({ uuid: raOrder.order_uuid } )
      const subscriptionBillingItems = this.subscriptionBillingItems;

      const businessEntityUuid = this.getBusinessEntityUuid;
      const accountUuid = this.businessEntity.account_uuid;
      const orderBillingItemUuid = subscriptionBillingItems[0].uuid;
      const orderUuid = raOrder?.order_uuid;

      const payload = {
        journey: 'registered_agent_service',
        businessEntityUuid,
        accountUuid,
        orderUuid,
        orderBillingItemUuid
      };

      await this.$store.dispatch('businessEntities/createCouponJourney', payload);
      await this.updateCouponStatus();
      this.closeJourneyModal();

    },
    async updateCouponStatus() {
      try {
       const raOrder = this.raOrder;
        if(raOrder) {
          const journeys = await this.fetchJourneyForAccount({ accountUuid: this.businessEntity.account_uuid });
          this.couponJourney = journeys.find(journey => journey.businessEntityUuid === this.businessEntity.business_entity_uuid && journey.slug === 'coupon_ra_v1');
          const subscriptionBillingItems = this.subscriptionBillingItems?.find(item => item.service === 'registered_agent_service');
          if (subscriptionBillingItems) {
            this.promotionData = await this.fetchOrderBillingItemPromotions({
              orderBillingItemUuid: subscriptionBillingItems.uuid,
              commitToStore: false
            });
          }
        } else {
          console.warn('No registered agent order found.');
        }

      } catch (error) {
        console.error('Failed to update coupon status', error);
      }
    },
  },
  watch: {
    businessEntityTasks: {
      async handler(tasks) {
        const raOrder = tasks?.find(task => task.order_task_type === 'registered_agent_service' && task.order_task_status === 'active');
        if (raOrder && raOrder.order_uuid && this.businessEntity?.account_uuid) {
          const journeys = await this.fetchJourneyForAccount({ accountUuid: this.businessEntity?.account_uuid });
          const couponJourney = journeys.find(journey => journey.businessEntityUuid === this.businessEntity.business_entity_uuid && journey.slug === 'coupon_ra_v1')
          this.couponJourney = couponJourney;
          this.fetchSubscriptionBillingItems({ uuid: raOrder.order_uuid }).then(() => {
            const subscriptionBillingItems = this.subscriptionBillingItems?.find(item => item.service === 'registered_agent_service');
            if (subscriptionBillingItems) {
              this.fetchOrderBillingItemPromotions({
                orderBillingItemUuid: subscriptionBillingItems.uuid,
                commitToStore: false
              }).then((promotionData) => {
                this.promotionData = promotionData;
              });
            } else {
              console.warn('No subscription billing items found for registered agent service.');
            }
          });
        } else {
          console.warn('No registered agent order found.');
        }
      },
      immediate: true
    }
  }

  };
</script>

<style lang="scss" scoped>
  h3 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  .menu {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 3px 3px 3px 0 #f8f8f8;
  }
  div .btn {
    width: 100%;
    margin-top: 10px;
  }
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
  #entity-type {
    text-transform: uppercase;
  }
  select {
    margin-top: 10px;
    width: 100%;
  }
  .wrap {
    word-wrap: break-word;
  }
</style>
