<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure you would like to delete the following document?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <strong>Name: </strong>{{ document.name }}
          <br />
          <strong>Content: </strong><a v-bind:href="document.meta_data" target="_blank">{{ document.meta_data }}</a>
        </div>
        <div class="modal-footer">
          <div class="btn btn-danger action" @click="handleDocumentDelete">Yes, I want to delete</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeleteDocumentModal',

  props: ['document'],

  methods: {
    handleDocumentDelete() {
      this.$store.dispatch('businessEntities/deleteDocument', { documentUuid: this.document.document_uuid })
        .then(() => {
          this.$emit('deleted');
        });
    }
  }

};
</script>

<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
  }
</style>
