<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <div id="business-entity-menu" class="menu">
          <h3>Actions</h3>
          <div class="btn btn-info" @click="handleSaveClick">Save</div>
          <div class="btn btn-dark" @click="handleCancelClick">Cancel</div>
        </div>
      </div>
      <div class="col-md-10">
        <form>
          <h2 class="header muted">Edit Website</h2>
          <div class="box">
            <div class="row">
              <div class="col">
                <dt>Website URI</dt><small>
                This field must be unique. If it does not save properly it is not a unique name in the system.
                Automated check coming soon.
              </small>
                <dd><input class="form-control" v-model.trim="websiteURL"/></dd>
              </div>
              <div class="col">
                <dt>Domain linked to {{ getWebsiteSubDomain }}</dt>
                <small>
                  Must set to true/yes after a domain's DNS has been configured to point to {{ getWebsiteSubDomain }}
                </small>
                <dd>
                  <div class="form-check">
                  <input v-model="zenbusinessDNS" class="form-check-input" type="checkbox" id="defaultCheck1">
                  <label class="form-check-label" for="defaultCheck1">
                    {{ website.zenbusiness_dns | yesno }}
                  </label>
                  </div>
                </dd>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <dt></dt>
                <dd>{{ websiteURL }}.{{getWebsiteSubDomain}}</dd>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-2"><dt>Active</dt></div>
              <div class="col-2"><dt>Deleted</dt></div>
            </div>
            <div class="row mb-2">
              <div class="col-2">
                <dd>
                  <div class="form-check">
                    <input v-model="website.active" class="form-check-input" type="checkbox" id="defaultCheck1">
                    <label class="form-check-label" for="defaultCheck1">
                      {{ website.active | yesno }}
                    </label>
                  </div>
                </dd>
              </div>
              <div class="col-2">
                <dd>
                  <div class="form-check">
                    <input v-model="website.deleted" class="form-check-input" type="checkbox" id="defaultCheck1">
                    <label class="form-check-label" for="defaultCheck1">
                      {{ website.deleted | yesno }}
                    </label>
                  </div>
                </dd>
              </div>
            </div>
          </div>

          <div v-bind:key="domain.domain_uuid" v-for="domain in domains" >
            <h2 class="header muted">Edit Domain - {{domain.domain_name}}</h2>
            <div class="box">
              <div class="row mb-2">
                <div class="col-12">
                  <small>
                    Do NOT include www. on the domain. If the domain partner request has not been sent yet, it will
                    register the domain listed here when triggered. Please use this area to update domains when a customer
                    changes their mind.
                  </small>
                </div>
                <div class="col-6">
                  <dt>Domain Name</dt>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6">
                  <dd>
                    <input class="form-control"
                          v-bind:class="{ 'is-invalid': !validateDomain(domain.domain_name) }"
                          v-model.trim="domain.domain_name"
                    /><span v-if="!validateDomain(domain.domain_name)" class="invalid-feedback"><small>Invalid Domain Name</small></span>
                  </dd>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-2"><dt>Domain Privacy</dt></div>
                <div class="col-2"><dt>Active</dt></div>
                <div class="col-2"><dt>Verified</dt></div>
                <div class="col-2"><dt>External</dt></div>
                <div class="col-2"><dt>Deleted</dt></div>
              </div>
              <div class="row mb-2">
                <div class="col-2">
                  <dd>
                    <div class="form-check">
                      <input v-model="domain.domain_privacy" class="form-check-input" type="checkbox" id="defaultCheck1">
                      <label class="form-check-label" for="defaultCheck1">
                        {{ domain.domain_privacy | yesno }}
                      </label>
                    </div>
                  </dd>
                </div>
                <div class="col-2">
                  <dd>
                    <div class="form-check">
                      <input v-model="domain.active" class="form-check-input" type="checkbox" id="defaultCheck2">
                      <label class="form-check-label" for="defaultCheck1">
                        {{ domain.active | yesno }}
                      </label>
                    </div>
                  </dd>
                </div>
                <div class="col-2">
                  <dd>
                    <div class="form-check">
                      <input v-model="domain.verified" class="form-check-input" type="checkbox" id="defaultCheck3">
                      <label class="form-check-label" for="defaultCheck1">
                        {{ domain.active | yesno }}
                      </label>
                    </div>
                  </dd>
                </div>
                <div class="col-2">
                  <dd>
                    <div class="form-check">
                      <input v-model="domain.is_external" class="form-check-input" type="checkbox" id="defaultCheck4">
                      <label class="form-check-label" for="defaultCheck4">
                        {{ domain.is_external | yesno }}
                      </label>
                    </div>
                  </dd>
                </div>
                <div class="col-2">
                  <dd>
                    <div class="form-check">
                      <input v-model="domain.deleted" class="form-check-input" type="checkbox" id="defaultCheck4">
                      <label class="form-check-label" for="defaultCheck4">
                        {{ domain.deleted | yesno }}
                      </label>
                    </div>
                  </dd>
                </div>
              </div>
              <DomainDnsEdit
                  :domain="domain"
                >
                </DomainDnsEdit>
              <div class="d-flex flex-row align-items-center w-50 mb-2" v-if="nameservers">
                <dt>Name servers (minimum of 2)</dt>
                <span v-if="!nameserverNumberValidator(domain.domain_uuid)" class="invalid-feedback flex-shrink-1 ms-2 w-auto"><small>A nameserver is missing</small></span>
                <ZgButton
                  :disabled="submitted"
                  type="button"
                  class="btn btn-primary btn-sm w-25 mt-0 ms-auto p-1"
                  @click="addNameserver(domain.domain_uuid)">
                    <template v-slot:title>
                      Add nameserver
                    </template>
                  </ZgButton>
              </div>
               <div
                class="d-flex flex-row align-items-center w-50 mb-2"
                v-bind:key="nameserver.domain_nameserver_uuid"
                v-for="(nameserver, index) in formNameservers[domain.domain_uuid]">
                  <span>{{ index + 1}}</span>
                  <input
                    class="form-control me-1 ms-1"
                    v-bind:class="{ 'is-invalid': !validateDomain(nameserver.name) }"
                    v-model.trim="nameserver.name"
                    @change="updateNameserver($event, domain.domain_uuid, nameserver.domain_nameserver_uuid)"
                  /><span v-if="!validateDomain(nameserver.name)" class="invalid-feedback"><small>Invalid nameserver</small></span>
                  <ZgButton
                    type="button"
                    class="btn btn-danger btn-sm w-25 ms-auto p-1"
                    :disabled="submitted"
                    @click="removeNameserver(domain.domain_uuid, nameserver.domain_nameserver_uuid, index)">
                      <template v-slot:title>
                        remove
                      </template>
                    </ZgButton>
              </div>

            </div>
          </div>


          <h2 class="header muted">Edit Emails</h2>
          <div class="box">
            <div class="row">
              <div class="col-12">
                <small>
                  Email Address needs to be set before sending the associated email Partner Request. Exclude @ and the domain when setting an email address
                </small>
              </div>
              <div class="col"><dt>Email User</dt></div>
              <div class="col"><dt>Email Address</dt></div>
              <div class="col"><dt>Domain Name</dt></div>
              <div class="col"><dt>Active</dt></div>
            </div>
            <div v-bind:key="email.email_uuid" v-for="email in emails" class="row">
              <div class="col">
                <dd><input class="form-control" v-model.trim="email.email_address"/></dd>
              </div>
              <div class="col">
                <dd>{{ email.email_address }}@{{ email.domain_name }}</dd>
              </div>
              <div class="col">
                <dd>
                    <select v-model="email.domain_uuid" @change="handleDomainChange($event.target.value, email)">
                  <option disabled value="">Select Domain</option>
                  <option v-for="domain in activeDomains" v-bind:key="domain.domain_uuid" :value="domain.domain_uuid">{{ domain.domain_name }}</option>
                  </select>
                </dd>
              </div>
              <div class="col">
                <dd>
                  <div class="form-check">
                    <input v-model="email.active" class="form-check-input" type="checkbox" id="defaultCheck1" :disabled="verifyEmailDeactivation(email.email_uuid)" />
                    <div v-if="verifyEmailDeactivation(email.email_uuid)" data-toggle="tooltip" data-placement="top" title="Sent this email before active it." class="disabled-text" style="color: grey;">Deactivated</div>
                    <label class="form-check-label" for="defaultCheck1">
                      {{ email.active | yesno }}
                    </label>
                  </div>
                </dd>
              </div>
            </div>
          </div>
          <h2 class="header muted">Domain Tools</h2>
          <div class="box">
            <div class="row">
              <div class="form-group col-4">
                <small><b>Please wait for the domain name to be validated before updating a customer's domain.</b></small>
                <input v-model="testingDomain" class="form-control" placeholder="nameyourbiz.com" name="Primary" id="primary" />
                <span> <b>www.{{ testingDomain }} </b></span>
                <span class="badge bg-secondary" v-if="this.domainAvailable === 'pending'">Checking domain availability...please wait</span>
                <span class="badge bg-danger" v-if="this.domainAvailable === 'unavailable'">Domain Taken</span>
                <span class="badge bg-success" v-if="this.domainAvailable === 'available'">Domain Available</span>
              </div>
              <div class="form-group col-2">
                <br/><br/>
                <span class="btn btn-primary btn-sm mt-5" @click="checkDomainAvailability">Check Domain Availability</span>
              </div>
            </div>
          </div>
          <template v-if="openSrsEnabled">
            <h2 class="header muted">OpenSRS Migration</h2>
            <div class="box">
              <div class="row">
                <div class="form-group col-8">
                  <div class="p-2 small">This will only show up if the OpenSRS Migration feature flag was turned on.</div>
                </div>
              </div>
            </div>
          </template>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';


import DomainDnsEdit from '@/v2/web-services/components/DomainDnsEdit.vue';
import { yesNoMixin, domainNameMixin } from '@/components/mixins';
import ZgButton from '@/components/common/Generics/ZgButton.vue';
import manifest from '@/manifest';


export default {
  name: 'BusinessEntityWebServicesEdit',

  mixins: [yesNoMixin, domainNameMixin],

  components: {
    ZgButton,
    DomainDnsEdit
  },

  data() {
    return {
      testingDomain: null,
      nameservers: {},
      formNameservers: {},
      changes: {},
      submitted: false,
      websiteURL: '',
      deactivationEmailUUID: [],
    };
  },

  computed: {
    ...mapState({
      businessEntity: (state) => state.businessEntities.businessEntity,
      accountUuid: (state) => state.businessEntities.businessEntity.account_uuid,
    }),
    ...mapGetters({
      getBusinessEntityUuid: 'businessEntities/getBusinessEntityUuid',
      website: 'webServices/getWebsite',
      domains: 'webServices/getDomains',
      emails: 'webServices/getEmails',
    }),
    activeDomains: {
      get() {
        return this.domains.filter(domain => domain.active === true);
      }
    },
    websiteDomain: {
      get() {
        return this.$store.state.webServices.website.website_domain;
      },
      set(value) {
        this.$store.commit('webServices/setWebsiteDomain', value);
      }
    },
    hasWebsite() {
      return Object.keys(this.website).length > 0;
    },
    zenbusinessDNS: {
      get() {
        return this.$store.state.webServices.website.zenbusiness_dns;
      },
      set(value) {
        this.$store.commit('webServices/setZenbusinessDNS', value);
      }
    },
    getWebsiteSubDomain() {
      return (this.onProd ? 'zensmb.com' :'dev.zensmb.com' );
    },
    onProd: {
      get() {
        return manifest.environment === 'production';
      }
    },
    domainAvailable: {
      get() {
        return this.$store.state.orderCreate.domainAvailable;
      },
      set(value) {
        this.$store.commit('orderCreate/setdomainAvailable', value);
      }
    },
    openSrsEnabled: {
      get() {
        return manifest.features.opensrsMigration.enabled
      }
    }
  },

  methods: {
    ...mapActions({
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity',
      fetchNameservers: 'webServices/fetchNameservers',
      fetchBusinessEntityWebServices:'webServices/fetchBusinessEntityWebServices',
      fetchEmails: 'webServices/fetchEmails',
      setBusinessEntityDomains: 'webServices/setBusinessEntityDomains',
      updateNameservers: 'webServices/updateNameservers',
      updateDomain: 'webServices/updateDomain',
      updateWebsite: 'webServices/updateWebsite',
      updateEmail: 'webServices/updateEmail',
      resetWebServicesState: 'webServices/resetWebServicesState'
    }),
    verifyEmailDeactivation(email_uuid) {
      if (this.deactivationEmailUUID.includes(email_uuid)) {
        return true;
      } else {
        return false;
      }
    },
    handleDomainChange(selectedDomainUUID, email) {
      const oldEmail = this.oldEmails.find(oldEmail => oldEmail.email_uuid === email.email_uuid);
      if (oldEmail.domain_uuid === null && selectedDomainUUID !== null) {
        this.deactivationEmailUUID.push(email.email_uuid);
        email.active = false;
      }
    },
    handleSaveClick() {
      this.submitted = true;
      const dispatchPromises = [];
      const nameserversPromise = this.updateNameservers(this.changes);
      if (nameserversPromise) {
        dispatchPromises.push(nameserversPromise);
      }
      if (this.hasWebsite) { // Website Save Block
        dispatchPromises.push(
          this.updateWebsite({
            websiteUuid: this.website.website_uuid,
            newWebsiteUrl: this.websiteURL,
            newWebsiteDomain: this.websiteDomain,
            newZenbusinessDNS: this.zenbusinessDNS,
            newActive: this.website.active,
            newDeleted: this.website.deleted
          })
        );
      }

      for (const domain of this.domains) { // Domains Save Block
        const domainUpdateBody = {
          domain_privacy: domain.domain_privacy,
          domain_name: domain.domain_name,
          verified: domain.verified,
          active: domain.active,
          is_external: domain.is_external,
          deleted: domain.deleted
        };
        dispatchPromises.push(
          this.updateDomain({domain_uuid: domain.domain_uuid, domainUpdateBody})
        );
      }

      for (const email of this.emails) { // Emails Save Block
        const emailData = {
          uuid: email.email_uuid,
          active: email.active,
          emailAddress: email.email_address,
          domainUUID: email.domain_uuid
        };
        dispatchPromises.push(
          this.updateEmail(emailData)
        );
      }

      Promise.all(dispatchPromises)
        .then(() => {
              this.$router.push({ name: 'BusinessEntity', params: { businessEntityUuid: this.getBusinessEntityUuid } });
        });
    },
    handleCancelClick() {
      this.fetchBusinessEntity({ uuid: this.getBusinessEntityUuid })
        .then(() => {
          this.$router.push({ name: 'BusinessEntity', params: { businessEntityUuid: this.getBusinessEntityUuid } });
        });
    },
    checkDomainAvailability() {
      this.$store.commit('orderCreate/setdomainAvailable', 'pending');
      this.$store.dispatch('orderCreate/checkDomainAvailability', { domainName: this.testingDomain })
        .then(() => {
        });
    },
    addNameserver(domainUuid) {
      const nameserverUuid = uuid();
      this.changes[domainUuid] = {
        ...this.changes[domainUuid],
        [nameserverUuid]: {
          name: '',
          create: true
        }
      }
      this.formNameservers[domainUuid].push({ name: '', domain_nameserver_uuid: nameserverUuid });
    },
    updateNameserver(event, domainUuid, uuidToUpdate) {
      const name = event?.target?.value;
      if (!name) return;
      const targetedExists = !!this.nameservers[domainUuid].find(
        ({ domain_nameserver_uuid }) => domain_nameserver_uuid === uuidToUpdate
      );

      this.changes[domainUuid] = {
        ...this.changes[domainUuid],
        [uuidToUpdate]: {
          name,
          create: !targetedExists
        }
      }
    },
    removeNameserver(domainUuid, uuidToDelete, index) {
      const targetedExists = !!this.nameservers[domainUuid].find(
        ({ domain_nameserver_uuid }) => domain_nameserver_uuid === uuidToDelete
      );

      const modifiedChanges = {
        ...this.changes[domainUuid]
      };

      if (!targetedExists) {
        delete modifiedChanges[uuidToDelete];
      } else {
        modifiedChanges[uuidToDelete] = {
          ...modifiedChanges[uuidToDelete],
          name: null
        };
      }

      this.changes[domainUuid] = {
        ...modifiedChanges
      };
      this.formNameservers[domainUuid].splice(index, 1);
    },
    nameserverNumberValidator(domainUuid) {
      const changes = this.formNameservers[domainUuid] || [];
      return changes.filter(({ name }) => name).length !== 1;
    }
  },

  async created() {
    await this.fetchBusinessEntity({ uuid: this.$route.params.businessEntityUuid });
    await this.fetchEmails({ uuid: this.$route.params.businessEntityUuid });
    await this.setBusinessEntityDomains({ uuid: this.$route.params.businessEntityUuid });
    this.oldEmails = cloneDeep(this.emails);
    this.websiteURL = this.website.website_url;
    const domainsUuids = this.domains.map(({ domain_uuid }) => domain_uuid);
    this.nameservers = await this.fetchNameservers(domainsUuids);
    this.formNameservers = cloneDeep(this.nameservers);
  },

  beforeDestroy() {
    this.resetWebServicesState();
  }
};
</script>

<style lang="scss" scoped>
  h3 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  #business-entity-menu {
    margin-top: 20px;
  }
  .menu {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    padding: 10px;
  }
  div .btn {
    width: 100%;
    margin-top: 10px;
    font-size: rem(14);
  }
  .invalid-feedback {
    display: block;
  }
</style>
