<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <div id="business-entity-menu" class="menu sticky-top">
          <h3>Actions</h3>
          <div class="btn btn-info" @click="handleSaveClick">Save</div>
          <div class="btn btn-dark" @click="handleCancelClick">Cancel</div>
        </div>
      </div>
      <div class="col-md-10">
        <form>
          <h2 class="header muted">Edit Filing Info</h2>
          <div class="box">
            <div class="row">
              <div class="col">
                <dt>Entity Name</dt>
                <dd><input class="form-control" v-model.trim="entityName"/></dd>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <dt>Entity Type</dt>
                <dd>
                   <select class="form-control" v-model="entityType">
                    <option disabled value="">Please select one</option>
                    <option>llc</option>
                    <option>corp</option>
                    <option>sole_prop</option>
                  </select>
                </dd>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <dt>Domestic Jurisdiction</dt>
                <dd>
                  <select class="form-control" v-model="domesticJurisdiction">
                    <option disabled value="">Please select one</option>
                    <option v-for="jur in allJurisdictions" v-bind:key="jur">{{ jur }}</option>
                  </select>
                </dd>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { US_JURISDICTIONS } from '../../assets/jurisdictions';

export default {
  name: 'BusinessEntityCreate',

  props: ['accountUuid'],

  data() {
    return {
      entityName: undefined,
      entityType: undefined,
      domesticJurisdiction: undefined
    };
  },

  computed: {
    allJurisdictions() {
      return US_JURISDICTIONS;
    }
  },

  methods: {
    handleCancelClick() {
      this.$store.dispatch('accounts/fetchAccount', { accountUuid: this.accountUuid })
        .then(() => {
          this.$router.push({ name: 'Account', params: { accountUuid: this.accountUuid } });
        });
    },
    handleSaveClick() {
      this.$store.dispatch('businessEntities/createBusinessEntity', {
        accountUuid: this.accountUuid,
        entityName: this.entityName,
        entityType: this.entityType,
        domesticJurisdiction: this.domesticJurisdiction
      })
        .then((response) => {
          this.$store.dispatch('businessEntities/fetchBusinessEntity', { uuid: response.data.business_entity_uuid });
          this.$router.push({ name: 'BusinessEntity', params: { businessEntityUuid: response.data.business_entity_uuid } });
        });
    }
  }

};
</script>

<style lang="scss" scoped>
  h3 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  #business-entity-menu {
    // Offset the sticky "Actions" panel so that it stays below the sticky navbar
    top: 75px;
  }
  .menu {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    padding: 10px;
  }
  select {
    max-width: max-content;
  }
  div .btn {
    width: 100%;
    margin-top: 10px;
  }
</style>
