<template>
    <label :for="id + '_button'" :class="{'active': isActive}" class="toggle__button">
        <span v-if="isActive" class="toggle__label">On</span>
        <span v-if="! isActive" class="toggle__label">Off</span>

        <input type="checkbox" :disabled="disabled" :id="id + '_button'" v-model="checkedValue">
        <span class="toggle__switch"></span>
    </label>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: 'primary'
        }, 
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentState: this.active
        }
    },
    computed: {
        isActive() {
            return this.currentState;
        },
        checkedValue: {
            get() {
                return this.currentState;
            },
            set(newValue) {
                this.currentState = newValue;
                this.$emit('change', newValue);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.toggle__button {
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
}
.toggle__button input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
}
.toggle__button .toggle__switch {
    display:inline-block;
    height: em(12);
    border-radius:6px;
    width: em(40);
    background: $toggle-grey;
    box-shadow: inset 0 0 1px $toggle-grey;
    position:relative;
    margin-left: em(10);
    transition: all .25s;
}
.toggle__button .toggle__switch::after, 
.toggle__button .toggle__switch::before {
    content: "";
    position: absolute;
    display: block;
    height: em(18);
    width: em(18);
    border-radius: 50%;
    left: 0;
    top: -3px;
    transform: translateX(0);
    transition: all .25s cubic-bezier(.5, -.6, .5, 1.6);
}
.toggle__button .toggle__switch::after {
    background: $clr-nav-primary;
    box-shadow: 0 0 1px #666;
}
.toggle__button .toggle__switch::before {
    background: $clr-nav-primary;
    opacity:0;
}
.active .toggle__switch {
    background: $zb-background;
    box-shadow: inset 0 0 1px $zb-background;
}
.active .toggle__switch::after,
.active .toggle__switch::before{
    transform:translateX(em(40) - em(18));
}
.active .toggle__switch::after {
    left: em(23);
    background: $zb-background;
    box-shadow: 0 0 1px $zb-background;
}
</style>
