<template>
  <tr>
    <td>
      <button class="btn btn-outline-secondary btn-sm" @click="handleDocumentClick">
        <i class="fas fa-eye"></i>
      </button>
      <ReadDocumentModal v-if="readClicked" @close="readClicked = false" v-bind:read_document="readDocument" v-bind:owner_read="ownerRead" />
    </td>
    <td class="text-break">
      <a v-if="!editing && canViewBof" :href="documentViewUrl" target="_blank">{{ documentLocal.name }}</a>
      <p v-else-if="!editing && !canViewBof" @click="handleDocumentClick" target="_blank">{{ documentLocal.name }}</p>
      <input v-else-if="editing && canViewBof" class="form-control" v-model="documentLocal.name"/>
    </td>
    <td class="text-break">
      <p v-if="!editing">{{ documentLocal.type }}</p>
      <select v-else v-model="documentLocal.type">
        <option value="formation">Formation</option>
        <option value="ein">EIN</option>
        <option value="soo">SOO</option>
        <option value="form_2553">Form 2553 (S-Corp)</option>
        <option disabled>-----</option>
        <option value="amendment">Amendment</option>
        <option value="annual_report">Annual Report</option>
        <option value="appoint_of_officer_and_directors">Appoint of Officer and Directors</option>
        <option value="bof_transcript">Beneficial Ownership Filing Transcript</option>
        <option value="business_license_report">Business License Report</option>
        <option value="cancel_dissolution_termination">Cancel/Dissolution/Termination</option>
        <option value="certified">Certified</option>
        <option value="compliance_notification">Compliance Notification</option>
        <option value="dba">DBA</option>
        <option value="foreign_qualification">Foreign Qualification</option>
        <option value="good_standing">Good Standing</option>
        <option value="insurance_bop">Insurance (BoP)</option>
        <option value="insurance_health">Insurance (Health)</option>
        <option value="mail">Mail</option>
        <option value="operating_agreement_corporate_bylaws">Operating Agreement/Corporate Bylaws</option>
        <option value="partner_unspecified">Partner - Unspecified</option>
        <option value="other">Other</option>
        <option value="publication">Publication</option>
        <option value="registered_agent_filing">Registered Agent Filing</option>
        <option value="reinstatement">Reinstatement</option>
        <option value="sop">SoP</option>
        <option value="state_resource">State Resource</option>
        <option value="taxes">Taxes</option>
      </select>
    </td>
    <td class="text-break">{{ documentLocal.uploaded_by }}</td>
    <td class="text-break">{{ documentLocal.created_datetime | moment("MMMM DD YYYY, h:mm:ss a") }}</td>
    <td>
      <p v-if="!editing">{{ !documentLocal.active | yesno }}</p>
      <input v-else class="form-check-input" type="checkbox" :id="documentLocal.document_uuid + '_cb'" v-model="hidden">
    </td>
    <td v-if="!isCorporateDocument">
      <div class="btn btn-info btn-sm" v-if="!editing && hasPermission('write:documents')" @click="handleEditClick">Edit</div>
      <div class="btn btn-danger btn-sm" v-if="!editing && documentLocal.active" @click="deleteClicked = true">Hide</div>
      <div class="btn btn-success btn-sm" v-if="editing" @click="handleSaveClick">Save</div>
      <div class="btn btn-danger btn-sm" v-if="editing" @click="handleCancelEditClick">Cancel</div>
      <DeleteDocumentModal v-if="deleteClicked"  @close="deleteClicked = false" @deleted="refresh" v-bind:document="document" />
    </td>
    <td v-else>
      <i class="fa fa-info-circle" content="Corporate documents can not be modified" v-tippy></i>
    </td>
  </tr>
</template>

<script>
import { hasPermissionMixin, yesNoMixin } from '@/components/mixins';
import DeleteDocumentModal from './delete-document-modal';
import ReadDocumentModal from './read-document-modal';
import { mapGetters } from 'vuex';

export default {
  name: 'DocumentListItem',

  props: ['document', 'documentParams', 'canViewBof'],

  mixins: [hasPermissionMixin, yesNoMixin],

  components: { DeleteDocumentModal, ReadDocumentModal },
  computed: {
    ...mapGetters({
      getBusinessEntityUuid: 'businessEntities/getBusinessEntityUuid'
    }),
    documentViewUrl() {
      return `/business-entity/${this.getBusinessEntityUuid}/documents/${this.documentLocal.document_uuid}/view`;
    },
    isCorporateDocument() {
      return this.documentLocal.uploaded_by === 'ZenBusiness PBC';
    },
    documentLocal(){
      return this.document
    }
  },
  data() {
    return {
      editing: false,
      deleteClicked: false,
      hidden: !this.document.active,
      oldValues: {
        name: '',
        subcategory: '',
        hidden: false
      },
      readDocument: [],
      ownerRead: false,
      readClicked: false
    };
  },
  methods: {
    handleSaveClick() {
      const docData = {
        name: this.documentLocal.name,
        subcategory: this.documentLocal.type,
        active: !this.hidden
      };

      this.$store.dispatch('businessEntities/updateDocument', { documentUuid: this.documentLocal.document_uuid, documentData: docData })
        .then(() => {
          this.$store.dispatch('businessEntities/fetchDocuments', { uuid: this.getBusinessEntityUuid });
        });
      this.editing = false;
    },
    handleCancelEditClick() {
      this.editing = false;
      this.documentLocal.name = this.oldValues.name;
      this.documentLocal.subcategory = this.oldValues.subcategory;
      this.hidden = this.oldValues.hidden;
    },
    handleEditClick() {
      this.editing = true;
      this.oldValues.name = this.documentLocal.name;
      this.oldValues.subcategory = this.documentLocal.subcategory;
      this.oldValues.hidden = this.hidden;
    },
    handleDocumentClick() {
      if (this.documentLocal.type === 'bof_transcript' && !this.canViewBof) {
        alert("You do not have access rights to view/download this document. Please check with your manager to see if your access rights should be updated.");
      } else {
        this.fetchReadDocument();
      }
  },
    refresh() {
      this.deleteClicked = false;
      this.$store.dispatch('businessEntities/fetchDocuments', this.documentParams);
    },
    async fetchReadDocument() {
      try {
        const readDocument = await this.$store.dispatch('businessEntities/fetchDocumentRead', { uuid: this.getBusinessEntityUuid, documentUuid: this.document.document_uuid });
        const readDocumentOwner = await this.$store.dispatch('businessEntities/fetchDocumentOwnerRead', { uuid: this.getBusinessEntityUuid, documentUuid: this.document.document_uuid });
        this.readDocument = readDocument.data.results;
        this.ownerRead = readDocumentOwner.data.owner_read;
        this.readClicked = true;
      } catch (error) {
        // eslint-disable-next-line no-empty
      }
    }
  }
};
</script>

<style scoped>
select {
  width: 100%;
}

input.form-check-input {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>
