<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <div id="business-entity-menu" class="menu">
          <h3>Actions</h3>
          <div class="btn btn-info" @click="handleSaveClick">Save</div>
          <div class="btn btn-dark" @click="handleCancelClick">Cancel</div>
        </div>
      </div>
      <div class="col-md-10">
        <form>
          <h2 class="header muted">Create External Domain</h2>
          <div class="box">
            <div class="row">
              <div class="col">
                <dt>Domain Name</dt><small>
                Domain only, exclude all subdomains i.e. www.
              </small>
                <dd><input class="form-control" placeholder="domain.com" v-model.trim="domainName"/></dd>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { yesNoMixin } from '@/components/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'BusinessEntityExternalDomainCreate',

  mixins: [yesNoMixin],

  data() {
    return {
      domainName: null
    };
  },

  computed: {
    ...mapGetters({
      getBusinessEntityUuid: 'businessEntities/getBusinessEntityUuid'
    }),
  },

  methods: {
    handleSaveClick() {
      const domainData = {
        businessEntityUUID: this.getBusinessEntityUuid,
        isExternal: true,
        domainName: this.domainName
      };

      this.$store.dispatch('webServices/createDomain', domainData)
        .then(() => {
          this.$router.push({ name: 'BusinessEntity', params: { businessEntityUuid: this.getBusinessEntityUuid } });
        });
    },
    handleCancelClick() {
      this.$store.dispatch('businessEntities/fetchBusinessEntity', { uuid: this.getBusinessEntityUuid })
        .then(() => {
          this.$router.push({ name: 'BusinessEntity', params: { businessEntityUuid: this.getBusinessEntityUuid } });
        });
    }
  },

  created() {
    this.$store.dispatch('businessEntities/fetchBusinessEntity', { uuid: this.getBusinessEntityUuid });
  }

};
</script>

<style lang="scss" scoped>
  h3 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  #business-entity-menu {
    margin-top: 20px;
  }
  .menu {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    padding: 10px;
  }
  div .btn {
    width: 100%;
    margin-top: 10px;
  }
</style>
