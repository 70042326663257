<template>
  <div>
    <div class="row heading">
      <div class="col">
        <h2 class="header">Documents</h2>
        <div class="box">
          <div class="row">
            <div class="col">
              <table class="table table-sm table-borderless">
                <thead>
                  <tr>
                    <th v-for="(header, i) in columnHeaders" :key="i">
                      <a href="" @click.prevent="sortingOptions(header.column)">{{header.header}}</a>
                      <span v-if="header.column === sortBy">
                        <i :class="[reverse ? 'fas fa-arrow-down' : 'fas fa-arrow-up' ]"></i>
                        <a href="" class="fa fa-times" @click.prevent="defaultSorting()" ></a>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <DocumentListItem :documentParams="documentParams" v-for="document in documents.results" v-bind:key="document.document_uuid" :document="document" :canViewBof="canViewBofDocument(document)"></DocumentListItem>
                  <Pagination @update:page="onPageChange" :page="page" :totalPages="totalPages" />


                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import { sortingDataMixin, hasPermissionMixin } from '../mixins';
import DocumentListItem from './document-list-item';
import Pagination from '../utils/pagination';

export default {
  name: 'BusinessEntityDocuments',

  mixins: [sortingDataMixin, hasPermissionMixin],

  components: {
    DocumentListItem,
    Pagination
  },

  data() {
    return {
      page:1,
      pageSize:20,
      sortBy: 'default',
      sortDirection: 'desc',
      reverse: false,
      columnHeaders: [
        { column: 'Read', header: 'Read' },
        { column: 'name', header: 'Name' },
        { column: 'type', header: 'Subcategory' },
        { column: 'uploaded_by', header: 'Uploaded By' },
        { column: 'created_datetime', header: 'Uploaded At' },
        { column: 'active', header: 'Hidden' }
      ]
    };
  },
  computed: {
    ...mapState('businessEntities', ['documents']),
    ...mapGetters({
      getBusinessEntityUuid: 'businessEntities/getBusinessEntityUuid'
    }),
    totalPages(){
      return Math.ceil(this.documents.total / this.pageSize)
    },
    documentParams(){
      return { 
      uuid: this.getBusinessEntityUuid,
      page: this.page,
      pageSize: this.pageSize,
      sortBy: this.sortBy === 'default' ? 'created_datetime' : this.sortBy,
      sortDirection: this.sortDirection
      }
    }
  },
  methods: {
    canViewBofDocument(document) {
      if (document.type === 'bof_transcript') {
        return this.hasPermission('access:bof_filers');
      }
      return true;    
    },
    onPageChange(value){
        this.page = value;
        this.fetchDocuments();

    },
     defaultSorting() {
      this.sortBy = 'default';
      this.reverse = false;
      this.sortDirection = 'desc';
      this.fetchDocuments();
    },
    sortingOptions(sortBy) {
    
      if (this.sortBy === sortBy) {
        this.reverse = !this.reverse;
      } else {
        this.reverse = false;
      }
      this.sortBy = sortBy;
      this.sortDirection = this.reverse ? 'desc' : 'asc';
      this.fetchDocuments();
    },
    fetchDocuments() {
      this.$store.dispatch('businessEntities/fetchDocuments', this.documentParams);
    }
  },

  created() {
    this.fetchDocuments();
  }
};
</script>

<style lang="scss" >
.pagination {
  display: -webkit-box !important;
}

</style>
