<template>
  <div>
    <div class="row heading">
      <div class="col">
        <h2 class="header">Website</h2>
        <div class="box">
          <div v-if="!hasWebsite" class="row">
            <div class="col">
              <dt>Entity has not purchased web services or created a website, yet.</dt>
            </div>
          </div>
          <div v-if="hasWebsite" class="row">
            <div class="col">
              <dt>Website URL</dt>
              <dd><a target="_blank" :href="websiteLinkURL">{{ websiteLinkURL }}</a></dd>
            </div>
            <div class="col">
              <dt>Website URI</dt>
              <dd>{{ websiteURL }}</dd>
            </div>
          </div>
          <div v-if="hasWebsite" class="row">
            <div class="col">
              <dt>{{ websiteDomainURL }}</dt>
              <dd>{{ website.zenbusiness_dns | yesno }}</dd>
            </div>
            <div class="col">
              <dt>Active</dt>
              <dd>{{ website.active | yesno }}</dd>
            </div>
          </div>
          <div v-if="hasWebsite" class="row">
            <div class="col">
              <dt>Is Published</dt>
              <dd>{{ isWebsitePublished | yesno }}</dd>
            </div>
            <div class="col">
              <dt>Website Editor Type</dt>
              <dd>{{ websiteType }}</dd>
            </div>
          </div>
          <div v-if="hasWebsite" class="row">
            <div class="col">
              <dt>Website Store Status</dt>
              <dd>{{ websiteStoreStatus }}</dd>
            </div>
            <div class="col">
              <dt>Website Labels</dt>
              <dd>{{ websiteLabels }}</dd>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row heading">
      <div class="col">
        <h2 class="header">Domains</h2>
        <div class="box" v-if="hasDomain">
          <DomainListItem
            v-bind:key="domain.domain_uuid"
            v-for="(domain, index) in domains"
            :website="website"
            :domain="domain"
            :divider="index === 0">
          </DomainListItem>
        </div>
      </div>
    </div>
    <div class="row heading">
      <div class="col">
        <h2 class="header">Emails</h2>
        <div class="box" v-if="hasEmail">
          <div class="row">
            <div class="col">
              <dt>Email User</dt>
            </div>
            <div class="col">
              <dt>Domain</dt>
            </div>
            <div class="col">
              <dt>Active</dt>
            </div>
            <div class="col">
              <dt>Order</dt>
            </div>
            <div class="col">
              <dt>OpenSRS</dt>
            </div>
          </div>
          <div v-bind:key="email.email_uuid" v-for="email in emails" class="row">
            <div class="col">
              <dd>{{ email.email_address | nullval }}
              </dd>
            </div>
            <div class="col">
              <dd>{{ email.domain_name}}</dd>
            </div>
            <div class="col">
              <dd>{{ email.active }}</dd>
            </div>
            <div class="col">
              <dd><router-link :to="{ name: 'Order', params: { uuid: email.order_uuid } }">{{ email.order_uuid | truncate(11) }}</router-link></dd>
            </div>
            <div class="col">
              <!-- Prior to 14-Sep-2021 the below link was value from email.external_data['email_link'] which has to be static and updated below for email. -->
              <!-- Can also update the partner request to have this value in DB for Email object after impact analysis -->
              <dd><a href="http://mail.zenbusiness.com" target="_blank">{{ 'http://mail.zenbusiness.com'}}</a></dd>
              <dd><span v-if="email.external_data['username'] !== undefined">u: {{ email.external_data['username'] }}</span></dd>
              <dd><span v-if="email.external_data['password'] !== undefined">p: {{ email.external_data['password'] }}</span></dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { yesNoMixin, nullMixin, textMixin } from '@/components/mixins';
import DomainListItem from '@/v2/web-services/components/domain-list-item';
import manifest from '@/manifest';

export default {
  name: 'BusinessEntityWebServices',

  mixins: [yesNoMixin, nullMixin, textMixin],

  props: ['businessEntityUuid'],

  components: {
    DomainListItem
  },

  filters: {
    publicUrl(value) {
      if (!value) return '';
      value = value.toString();
      return value.slice(0, -5);
    }
  },

  computed: {
    ...mapGetters({
      website: 'webServices/getWebsite',
      domains: 'webServices/getDomains',
      emails: 'webServices/getEmails',
      getBusinessEntityAccountUuid: 'businessEntities/getBusinessEntityAccountUuid',
      getBusinessEntityWebsiteUrl: 'businessEntities/getBusinessEntityWebsiteUrl',
    }),
    ...mapState({
      businessEntityTasks: (state) => state.businessEntities.businessEntityTasks,
    }),
    websiteURL() {
      return this.website.website_url
    },
    websiteLinkURL() {
      return 'https://' + this.website.website_url + (this.onProd ? '' : '.dev') + '.zensmb.com'
    },
    websiteDomainURL() {
      return (this.website.website_url == null) ? '' : ('Domain linked to ' + (this.onProd ? '' : '.dev') + '.zensmb.com')
    },
    hasWebsite() {
      return Object.keys(this.website).length > 0;
    },
    hasDomain() {
      return this.domains.length > 0;
    },
    hasEmail() {
      return this.emails.length > 0;
    },
    isWebsitePublished() {
      return this.website.publish_status === 'PUBLISHED' ? true : false;
    },
    websiteType() {
      if (this.website?.website_type === 'dudaSimplePlus' && this.website?.duda_active) return 'Simple';
      if (this.website?.website_type === 'dudaEcommDemo' && this.website?.duda_active) return 'Ecommerce with Demo Store';
      if (this.website?.website_type === 'dudaEcomm' && this.website?.duda_active) return 'Ecommerce';
      return  'None';
    },
    websiteStoreStatus() {
      return this.website.website_store_status;
    },
    websiteLabels() {
      return this.website.website_labels?.join(', ');
    },
    onProd: {
      get() {
        return manifest.environment === 'production';
      }
    },
  },
  methods:{
    ...mapActions({
      setBusinessEntityDomains: 'webServices/setBusinessEntityDomains',
      setBusinessEntityWebsites: 'webServices/setBusinessEntityWebsites',
      fetchEmails: 'webServices/fetchEmails',
      fetchDomains: 'webServices/fetchDomains',
      fetchBusinessEntityWebSites: 'webServices/fetchBusinessEntityWebSites',
      resetWebServicesState: 'webServices/resetWebServicesState'
    }),
  },
  created() {
    this.fetchBusinessEntityWebSites({ uuid: this.businessEntityUuid, website_url: this.getBusinessEntityWebsiteUrl, account_uuid: this.getBusinessEntityAccountUuid });
    this.setBusinessEntityDomains( { uuid: this.businessEntityUuid });
    this.setBusinessEntityWebsites( { businessEntityUuid: this.businessEntityUuid, accountUuid: this.getBusinessEntityAccountUuid });
    this.fetchEmails({ uuid: this.businessEntityUuid });
    this.fetchDomains({ uuid: this.businessEntityUuid });
  },
  beforeDestroy() {
    if (this.$router.currentRoute.name !== 'BusinessEntityWebServicesEdit') {
      this.resetWebServicesState();
    }
  }
};

</script>

<style lang="scss" scoped>

</style>
