<template>
  <div>
    <div class="row heading">
      <div class="col">
        <h2 class="header">Changelog</h2>
        <div class="box">
          <div class="row">
            <div class="col">
              <table class="table table-sm table-borderless">
                <thead>
                <tr>
                  <th>Updated At</th>
                  <th>Updated By</th>
                  <th>Details</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <ChangelogListItem
                  v-for="(change, timestamp) in auditRecords"
                  v-bind:key="timestamp"
                  :change="change"
                  :timestamp="timestamp">
                </ChangelogListItem>
                <!--{{ businessEntity.business_entity_audit_records }}-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ChangelogListItem from './changelog-list-item';

export default {
  name: 'BusinessEntityChangelog',

  components: {
    ChangelogListItem
  },

  created() {
    this.$store.dispatch('businessEntities/fetchAuditRecords', { uuid: this.getBusinessEntityUuid });
  },

  computed: {
    ...mapState({
      auditRecords: (state) => state.businessEntities.audit_records
    }),
    ...mapGetters({
      getBusinessEntityUuid: 'businessEntities/getBusinessEntityUuid'
    })
  }
};
</script>

<style lang="scss" scoped>

</style>
