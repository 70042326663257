<template>
  <div>
    <vue-headful title="Business Entities" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto">
          <h1>Business Entities</h1>
        </div>
        <div class="col-auto top10 mb-2">
          <form class="form-inline ms-auto" v-on:submit="searchForBusinessEntity" v-on:submit.prevent v-on:submit.stop>
            <div class="form-group">
              <input class="form-control-sm mx-2" placeholder="Business ID" type="text" v-model="jurisdictionFilingNumber" />
              <input class="form-control-sm mx-2" placeholder="Entity Name" type="text" v-model="businessEntityName" />
              <input class="btn-info btn-sm" type="submit" value="Search" />
            </div>
          </form>
        </div>
      </div>
      <table class="table table-sm">
        <tr>
            <th v-for="(header, i) in columnHeaders" :key="i">
              <a href="" @click.prevent="sortingOptions(header.column)">{{header.header}}</a>
              <span v-if="header.column === sortBy">
                <i :class="[reverse ? 'fas fa-arrow-down' : 'fas fa-arrow-up' ]"></i>
                <a href="" class="fa fa-times" @click.prevent="defaultSorting()" ></a>
              </span>
            </th>
        </tr>
        <BusinessEntityListItem v-for="businessEntity in sortedData" :key="businessEntity.business_entity_uuid" :businessEntity="businessEntity" />
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { sortingDataMixin } from '../mixins';
import BusinessEntityListItem from './business-entity-list-item';

export default {
  name: 'BusinessEntityList',

  mixins: [sortingDataMixin],

  components: {
    BusinessEntityListItem
  },
  computed: {
    ...mapState('businessEntities', ['businessEntities']),
    sortedData() {
      return this.businessEntities.concat().sort(this.sorting(this.sortBy, this.reverse));
    }
  },
  data() {
    return {
      sortBy: 'default',
      reverse: false,
      columnHeaders: [
        { column: 'business_entity_name', header: 'Entity Name' },
        { column: 'business_entity_data.domestic_jurisdiction', header: 'Jurisdiction' },
        { column: 'business_entity_type', header: 'Type' },
        { column: 'active_flag', header: 'Active' },
        { column: 'salesforce', header: 'Salesforce' }
      ],
      businessEntityName: '',
      jurisdictionFilingNumber: ''
    };
  },
  methods: {
    searchForBusinessEntity() {
      if(this.jurisdictionFilingNumber !== ''){
        this.$store.dispatch('businessEntities/searchByFilingNumber', { jurisdictionFilingNumber: this.jurisdictionFilingNumber });
      } else {
        this.$store.dispatch('businessEntities/searchByName', { name: this.businessEntityName });
      }
    },
    defaultSorting() {
      this.sortBy = 'default';
      this.reverse = false;
    },
    sortingOptions(sortBy) {
      if (this.sortBy === sortBy) {
        this.reverse = !this.reverse;
      } else {
        this.reverse = false;
      }

      this.sortBy = sortBy;
    }
  },
  mounted() {
    this.$store.dispatch('businessEntities/fetchBusinessEntities');
  }
};
</script>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
  }
  .top25 {
    margin-top: 25px;
  }
  .top10 {
    margin-top: 10px;
  }
  .account-list-header-center {
    text-align: center;
  }
</style>
